import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Dorator</h1>
    <p>The Famous Findora Validator.</p>
    <p>Professional. Reliable. Green.</p>
  </Layout>
)

export default IndexPage
